import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as Sentry from '@sentry/angular';
import { of, timer } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

import * as fromGenerated from '../../_generated';
import * as fromChats from '../../chats';
import * as fromFeatureFlags from '../../feature-flags';

import { HttpContext } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { IS_AUTH_INTERCEPTOR_ENABLED } from '../auth.interceptor';
import { AuthActions } from './auth.actions';
import { AuthState } from './auth.reducer';

@Injectable()
export class AuthEffects {
  private readonly actions$ = inject(Actions);
  private readonly userService = inject(fromGenerated.UsersService);
  private readonly authService = inject(fromGenerated.AuthService);
  private readonly store = inject(Store<AuthState>);

  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.login),
        switchMap(
          () =>
            (window.location.href = `${environment.appApiUrl}/v1/auth/login`)
        )
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        switchMap(
          () =>
            (window.location.href = `${environment.appApiUrl}/v1/auth/logout`)
        )
      ),
    { dispatch: false }
  );

  authenticateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authenticateUser),
      switchMap(() =>
        this.userService
          .usersControllerGetMe(undefined, undefined, {
            context: new HttpContext().set(IS_AUTH_INTERCEPTOR_ENABLED, false),
          })
          .pipe(
            map((user) => AuthActions.authenticateUserSuccess({ user: user })),
            catchError((error: Error) =>
              of(AuthActions.authenticateUserFailure({ error: error.message }))
            )
          )
      )
    )
  );

  authenticateUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authenticateUserSuccess),
      tap(({ user }) => {
        Sentry.setUser({
          id: user.id,
          email: user.email,
          username: `${user.firstName || 'n/a'} ${user.lastName || 'n/a'}`,
        });
      }),
      switchMap(({ user }) => [
        AuthActions.startRefreshTokensTimer(),
        fromFeatureFlags.FeatureFlagsActions.identifyUser({ user }),
      ])
    )
  );

  startRefreshTokensInterval$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.startRefreshTokensTimer),
        switchMap(() =>
          timer(environment.refreshTokensTimerDue).pipe(
            tap(() => this.store.dispatch(AuthActions.refreshTokens()))
          )
        )
      ),
    { dispatch: false }
  );

  refreshTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshTokens),
      exhaustMap(() =>
        this.authService.authControllerRefreshTokens().pipe(
          map(() => {
            return AuthActions.refreshTokensSuccess();
          }),
          catchError((error: Error) =>
            of(AuthActions.refreshTokensFailure({ error: error.message }))
          )
        )
      )
    )
  );

  refreshTokensSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshTokensSuccess),
      switchMap(() => [
        AuthActions.startRefreshTokensTimer(),
        fromChats.ChatsSocketActions.reconnect(),
      ])
    )
  );
}
