import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NEW_CHAT_TITLE_DEFAULT } from './chats.constants';

@Injectable()
export class ChatsHelper {
  private readonly translateService = inject(TranslateService);

  public getChatTitle(chatTitle: string | undefined): string {
    if (!chatTitle || chatTitle === NEW_CHAT_TITLE_DEFAULT) {
      return this.translateService.instant(
        `NAVIGATION_ITEMS.${NEW_CHAT_TITLE_DEFAULT}`
      ) as string;
    }

    return chatTitle;
  }
}
