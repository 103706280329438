import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivateFn,
  ResolveFn,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AssistantActions } from './store/assistants.actions';
import { AssistantsState } from './store/assistants.reducer';

export const selectAssistantResolverFn: ResolveFn<void> = (
  route: ActivatedRouteSnapshot
) => {
  const assistantUuid = route.paramMap.get('assistantUuid');
  const assistantStore = inject(Store<AssistantsState>);

  if (assistantUuid) {
    assistantStore.dispatch(
      AssistantActions.selectAssistant({ uuid: assistantUuid })
    );
  }
};

export const unselectAssistantCanDeactivateFn: CanDeactivateFn<
  boolean
> = () => {
  const assistantStore = inject(Store<AssistantsState>);

  assistantStore.dispatch(AssistantActions.unselectAssistant());

  return true;
};
