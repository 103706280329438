import { HttpContext } from '@angular/common/http';
import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, map, of } from 'rxjs';
import { environment } from '../../environments/environment';
import * as fromGenerated from '../_generated';
import { IS_AUTH_INTERCEPTOR_ENABLED } from './auth.interceptor';
import { AuthActions } from './store/auth.actions';
import { AuthState, authFeature } from './store/auth.reducer';

/**
 * This is a guard that checks if the user is authenticated
 * and make sure that the user is redirected to the login page.
 */
export const authGuard: CanActivateFn = () => {
  const userService = inject(fromGenerated.UsersService);
  const authStore = inject<Store<AuthState>>(Store);
  const user = authStore.selectSignal(authFeature.selectUser);

  return user() !== null
    ? true
    : userService
        .usersControllerGetMe(undefined, undefined, {
          context: new HttpContext().set(IS_AUTH_INTERCEPTOR_ENABLED, false),
        })
        .pipe(
          map((user) => {
            authStore.dispatch(AuthActions.authenticateUserSuccess({ user }));
            return true;
          }),
          catchError((error: Error) => {
            console.error(error);
            window.location.href = `${environment.appUiUrl}/login`;
            return of(false);
          })
        );
};
