import { inject, Injectable, isDevMode } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { tap } from 'rxjs';
import { environment } from '../../environments/environment';
import * as fromGenerated from '../_generated';
import * as fromAuth from '../auth';
import * as fromAssistants from './assistants';
import { ChatWithMessagesActions } from './store/chats.actions';
import { ChatsState } from './store/chats.reducer';

interface ApiError {
  message: string;
  name: string;
  response: {
    error: string;
    message: string;
    statusCode: number;
  };
  status: number;
}

@Injectable()
export class ChatsSocket extends Socket {
  private readonly store = inject(Store<ChatsState>);

  constructor() {
    super({
      url: `${environment.appApiUrl}/chats`,
      options: {
        autoConnect: false,
        reconnection: true,
        closeOnBeforeunload: true,
        withCredentials: true,
        protocols: ['polling', 'websocket'],
        upgrade: true,
      },
    } satisfies SocketIoConfig);

    this.fromEvent<{
      type: string;
      chatWithMessages: fromGenerated.ChatWithMessagesView;
    }>('chat.message.answered')
      .pipe(
        takeUntilDestroyed(),
        tap(({ chatWithMessages }) => {
          this.store.dispatch(
            ChatWithMessagesActions.receivedChatWithMessagesFromSocketSuccess({
              chatWithMessages,
            })
          );
        })
      )
      .subscribe();

    this.fromEvent<{
      type: string;
      assistant: fromGenerated.AssistantView;
    }>('assistants.status.updated')
      .pipe(
        takeUntilDestroyed(),
        tap(({ assistant }) => {
          this.store.dispatch(
            fromAssistants.AssistantActions.receivedAssistantStatusUpdatedFromSocket(
              {
                assistant,
              }
            )
          );
        })
      )
      .subscribe();

    this.fromEvent<ApiError>('error')
      .pipe(
        takeUntilDestroyed(),
        tap((error) => {
          console.error(`${error.name}: ${error.message}`);
          if (
            error.status === 401 &&
            error.message.includes("didn't include jwt tokens in cookie")
          ) {
            console.warn('Refreshing tokens');
            this.store.dispatch(fromAuth.AuthActions.refreshTokens());
          }
        })
      )
      .subscribe();

    if (isDevMode()) {
      // eslint-disable-next-line no-console
      this.onAny((event) => console.info('[ChatsSocket] %s', event));
    }
  }
}
