import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { ItemNavigation } from '@serious-system';
import { DateTime } from 'luxon';
import * as fromGenerated from '../../_generated';
import {
  ChatActions,
  ChatModalActions,
  ChatsActions,
  ChatWithMessagesActions,
} from './chats.actions';

type ChatViewWithRequiredTitle = Omit<fromGenerated.ChatView, 'title'> &
  Required<Pick<fromGenerated.ChatView, 'title'>>;

const isChatViewWithRequiredTitle = (
  chat: fromGenerated.ChatView
): chat is ChatViewWithRequiredTitle => {
  return !!chat.title;
};

export const chatsFeatureKey = 'chats';

interface ChatWithMessages extends fromGenerated.ChatView {
  messages?: fromGenerated.ChatWithMessagesView['messages'];
}

export interface ChatsState extends EntityState<ChatWithMessages> {
  selectedChatId: number | null;
  isLoading: boolean;
  loaded: boolean;
  error: string | null;
  isSideContainerOpened: boolean;
  isThreadsListingContainerOpened: boolean;
}

export interface ChatModalState {
  isDeleteChatModalOpened: boolean;
}

const entityAdapter: EntityAdapter<ChatWithMessages> =
  createEntityAdapter<ChatWithMessages>({
    selectId: (view) => view.id,
    sortComparer: (a, b) => b.updatedAt.localeCompare(a.updatedAt),
  });

export const initialChatsState: ChatsState & ChatModalState =
  entityAdapter.getInitialState({
    selectedChatId: null,
    isLoading: true,
    loaded: false,
    error: null,
    isSideContainerOpened: false,
    isThreadsListingContainerOpened: true,
    isDeleteChatModalOpened: false,
  });

export const chatsFeature = createFeature({
  name: chatsFeatureKey,
  reducer: createReducer(
    initialChatsState,
    on(ChatModalActions.openDeleteChatModal, (state) => ({
      ...state,
      isDeleteChatModalOpened: true,
    })),
    on(ChatModalActions.closeDeleteChatModal, (state) => ({
      ...state,
      isDeleteChatModalOpened: false,
    })),
    on(ChatActions.selectChat, (state, { id }) => ({
      ...state,
      selectedChatId: id,
      error: null,
    })),
    on(ChatActions.unselectChat, (state) => ({
      ...state,
      selectedChatId: null,
      error: null,
    })),
    on(ChatActions.loadEmptyChat, (state) => ({
      ...state,
      selectedChatId: null,
      error: null,
    })),
    on(ChatsActions.addChatSuccess, (state, { chat }) =>
      entityAdapter.addOne(chat, state)
    ),
    on(ChatActions.toggleLoading, (state, { isLoading }) => ({
      ...state,
      isLoading,
    })),
    on(ChatsActions.loadChatsSuccess, (state, { chats }) => ({
      ...entityAdapter.upsertMany(chats, state),
    })),
    on(ChatsActions.loadChatsFailure, (state, { error }) => ({
      ...state,
      error,
    })),
    on(ChatsActions.deleteChat, (state, { id }) =>
      entityAdapter.removeOne(id, state)
    ),
    on(ChatsActions.deleteChatFailure, (state, { error }) => ({
      ...state,
      error,
    })),
    on(ChatWithMessagesActions.loadChatWithMessages, (state) => ({
      ...state,
      isLoading: true,
    })),
    on(
      ChatWithMessagesActions.loadChatWithMessagesSuccess,
      (state, { chatWithMessages }) =>
        entityAdapter.upsertOne(chatWithMessages, {
          ...state,
          isLoading: false,
        })
    ),
    on(
      ChatWithMessagesActions.receivedChatWithMessagesFromSocketSuccess,
      (state, { chatWithMessages }) =>
        entityAdapter.upsertOne(chatWithMessages, {
          ...state,
          isLoading: false,
        })
    ),
    on(
      ChatWithMessagesActions.loadChatWithMessagesFailure,
      (state, { error }) => ({
        ...state,
        error,
      })
    ),
    on(
      ChatWithMessagesActions.addChatMessage,
      (state, { content, assistant }) => {
        const selectedChatId = state.selectedChatId;

        if (selectedChatId !== null) {
          const currentChat = state.entities[selectedChatId];

          if (currentChat) {
            const messages = currentChat.messages?.length
              ? [...currentChat.messages]
              : [];

            if (assistant) {
              messages.push({
                id: crypto.getRandomValues(new Uint32Array(1))[0],
                content: assistant.description,
                isFromUser: false,
                chatId: currentChat.id,
                createdAt: DateTime.local().toISO(),
                updatedAt: DateTime.local().toISO(),
              });
            }

            messages.push({
              id: crypto.getRandomValues(new Uint32Array(1))[0],
              content,
              isFromUser: true,
              chatId: currentChat.id,
              createdAt: DateTime.local().toISO(),
              updatedAt: DateTime.local().toISO(),
            });

            return entityAdapter.updateOne(
              {
                id: currentChat.id,
                changes: {
                  updatedAt: DateTime.local().toISO(),
                  _count: {
                    ...currentChat._count,
                    messages: messages.length,
                  },
                  messages,
                },
              },
              state
            );
          }
        }

        return state;
      }
    ),
    on(ChatWithMessagesActions.addChatMessageFailure, (state, { error }) => ({
      ...state,
      error,
    })),
    on(ChatActions.openSideContainer, (state) => ({
      ...state,
      isSideContainerOpened: true,
    })),
    on(ChatActions.closeSideContainer, (state) => ({
      ...state,
      isSideContainerOpened: false,
    })),
    on(ChatActions.openThreadsListingContainer, (state) => ({
      ...state,
      isThreadsListingContainerOpened: true,
    })),
    on(ChatActions.closeThreadsListingContainer, (state) => ({
      ...state,
      isThreadsListingContainerOpened: false,
    }))
  ),
  extraSelectors: ({ selectChatsState, selectSelectedChatId }) => {
    const entitySelectors = entityAdapter.getSelectors(selectChatsState);

    return {
      ...entitySelectors,
      selectChatsAsItemNavigation: createSelector(
        selectSelectedChatId,
        entitySelectors.selectAll,
        (selectedChatId, chats) =>
          /**
           * We are filtering out chats without a title because we don't want to
           * show them in the navigation. Note that we should only have 1 chat
           * per user that doesn't have a title, which is the "Home Chat" up until
           * a message has been added to it.
           */
          chats
            .filter((chat) => chat._count.messages)
            .filter(isChatViewWithRequiredTitle)
            .sort(
              (a, b) =>
                DateTime.fromISO(b.updatedAt).toMillis() -
                DateTime.fromISO(a.updatedAt).toMillis()
            )
            .map<ItemNavigation>(
              (chat) =>
                ({
                  id: chat.id,
                  title: chat.title,
                  selected: chat.id === selectedChatId,
                  date: DateTime.fromISO(chat.updatedAt).toLocal(),
                } satisfies ItemNavigation)
            )
      ),
      selectSelectedChat: createSelector(
        selectSelectedChatId,
        entitySelectors.selectEntities,
        (selectedChatId, entities) =>
          selectedChatId ? entities[selectedChatId] : null
      ),
    };
  },
});
