import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, timer, zip } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromGenerated from '../../_generated';
import { layoutFeature, LayoutState } from '../../layout/store/layout.reducer';
import { LOADING_TIME_FOR_MESSAGE } from '../chats.constants';
import { ChatsSocket } from '../chats.socket';
import {
  ChatActions,
  ChatsActions,
  ChatsSocketActions,
  ChatWithMessagesActions,
} from './chats.actions';
import { chatsFeature, ChatsState } from './chats.reducer';

@Injectable()
export class ChatsEffects {
  private readonly actions$ = inject(Actions);
  private readonly chatsService = inject(fromGenerated.ChatsService);
  private readonly store = inject(Store<ChatsState>);
  private readonly layoutStore = inject(Store<LayoutState>);
  private readonly router = inject(Router);
  private readonly chatsSocket = inject(ChatsSocket);
  private readonly CONVERSATION_URL = '/chats/conversation';

  unselectChatOnNavigation$ = createEffect(() =>
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      filter((event) => {
        const previousUrl =
          this.router
            .getCurrentNavigation()
            ?.previousNavigation?.finalUrl?.toString() ?? '';
        return (
          previousUrl.includes(this.CONVERSATION_URL) &&
          !event.url.includes(this.CONVERSATION_URL)
        );
      }),
      map(() => ChatActions.unselectChat())
    )
  );

  receivedChatWithMessagesFromSocketSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ChatWithMessagesActions.receivedChatWithMessagesFromSocketSuccess
        ),
        exhaustMap(async ({ chatWithMessages }) => {
          if (chatWithMessages.assistantId) {
            await this.router.navigate([this.CONVERSATION_URL]);
          }
        })
      ),
    { dispatch: false }
  );

  loadChats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.loadChats),
      exhaustMap(() =>
        this.chatsService.chatsControllerGetChats().pipe(
          map((chats) => ChatsActions.loadChatsSuccess({ chats })),
          catchError((error: Error) =>
            of(ChatsActions.loadChatsFailure({ error: error.message }))
          )
        )
      )
    )
  );

  loadChatsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.loadChatsSuccess),
      withLatestFrom(this.store.select(chatsFeature.selectSelectedChatId)),
      map(([{ chats }, selectedChatId]) =>
        ChatActions.selectChat({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: selectedChatId ?? chats.find((chat) => !chat._count.messages)!.id,
        })
      )
    )
  );

  addChatSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.addChatSuccess),
      map(({ chat }) => ChatActions.selectChat({ id: chat.id }))
    )
  );

  selectChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.selectChat),
      map(({ id }) => ChatWithMessagesActions.loadChatWithMessages({ id }))
    )
  );

  openThreadsListingContainer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.openThreadsListingContainer),
      withLatestFrom(this.layoutStore.select(layoutFeature.selectIsMobile)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filter(([_, isMobile]) => isMobile),
      map(() => ChatActions.unselectChat())
    )
  );

  loadEmptyChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadEmptyChat),
      map(() => ChatsActions.loadChats())
    )
  );

  loadChatWithMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatWithMessagesActions.loadChatWithMessages),
      switchMap(({ id }) =>
        zip(
          this.chatsService.chatsControllerGetChatWithMessages(id),
          timer(LOADING_TIME_FOR_MESSAGE)
        ).pipe(
          map(([chatWithMessages]) =>
            ChatWithMessagesActions.loadChatWithMessagesSuccess({
              chatWithMessages,
            })
          ),
          catchError((error: Error) =>
            of(
              ChatWithMessagesActions.loadChatWithMessagesFailure({
                error: error.message,
              })
            )
          )
        )
      )
    )
  );

  addChatMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatWithMessagesActions.addChatMessage),
      withLatestFrom(this.store.select(chatsFeature.selectSelectedChatId)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filter(([_, selectedChatId]) => !!selectedChatId),
      switchMap(([{ content, assistant }, selectedChatId]) =>
        this.chatsService
          .chatsControllerAddChatMessage(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            selectedChatId!,
            assistant ? { content, assistantUuid: assistant.uuid } : { content }
          )
          .pipe(
            map(() => ChatWithMessagesActions.addChatMessageSuccess()),
            catchError((error: Error) =>
              of(
                ChatWithMessagesActions.addChatMessageFailure({
                  error: error.message,
                })
              )
            )
          )
      )
    )
  );

  deleteChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.deleteChat),
      switchMap(({ id }) =>
        this.chatsService.chatsControllerDeleteChatWithMessages(id).pipe(
          map(() => ChatsActions.deleteChatSuccess({ id })),
          catchError((error: Error) =>
            of(ChatsActions.deleteChatFailure({ error: error.message }))
          )
        )
      )
    )
  );

  deleteChatSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.deleteChatSuccess),
      map(() => ChatActions.loadEmptyChat())
    )
  );

  chatsSocketConnect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatsSocketActions.reconnect),
        tap(() => {
          void this.chatsSocket.disconnect();
          void this.chatsSocket.connect((error) => console.error(error));
        })
      ),
    { dispatch: false }
  );
}
