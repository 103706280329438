import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import * as fromGenerated from '../../../_generated';
import {
  AssistantActions,
  AssistantModalActions,
  AssistantsActions,
} from './assistants.actions';

@Injectable()
export class AssistantsEffects {
  private readonly actions$ = inject(Actions);
  private readonly assistantsService = inject(fromGenerated.AssistantsService);

  loadAssistants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistantsActions.loadAssistants),
      switchMap(() =>
        this.assistantsService.assistantsControllerGetAssistants().pipe(
          map((assistants) =>
            AssistantsActions.loadAssistantsSuccess({ assistants })
          ),
          catchError((error: Error) =>
            of(
              AssistantsActions.loadAssistantsFailure({ error: error.message })
            )
          )
        )
      )
    )
  );

  saveAssistant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistantModalActions.saveAssistant),
      switchMap(({ assistant }) =>
        this.assistantsService
          .assistantsControllerCreateAssistant(assistant)
          .pipe(
            switchMap((assistant) => [
              AssistantModalActions.saveAssistantSuccess({ assistant }),
              AssistantModalActions.closeSaveAssistantModal(),
            ]),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            catchError((error: any) =>
              of(
                AssistantModalActions.saveAssistantFailure({
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  error: (error?.error?.name as string) ?? error?.message,
                })
              )
            )
          )
      )
    )
  );

  editAssistant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistantModalActions.editAssistant),
      switchMap(({ uuid, assistant }) =>
        this.assistantsService
          .assistantsControllerUpdateAssistant(uuid, assistant)
          .pipe(
            switchMap((assistant) => [
              AssistantModalActions.editAssistantSuccess({ assistant }),
              AssistantModalActions.closeSaveAssistantModal(),
            ]),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            catchError((error: any) =>
              of(
                AssistantModalActions.editAssistantFailure({
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  error: (error?.error?.name as string) ?? error?.message,
                })
              )
            )
          )
      )
    )
  );

  addAssistant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistantActions.addAssistant),
      switchMap(({ uuid }) =>
        this.assistantsService.assistantsControllerActivateAssistant(uuid).pipe(
          map(() => AssistantActions.addAssistantSuccess({ uuid })),
          catchError((error: Error) =>
            of(AssistantActions.addAssistantFailure({ error: error.message }))
          )
        )
      )
    )
  );

  removeAssistant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistantActions.removeAssistant),
      switchMap(({ uuid }) =>
        this.assistantsService
          .assistantsControllerDeactivateAssistant(uuid)
          .pipe(
            map(() => AssistantActions.removeAssistantSuccess({ uuid })),
            catchError((error: Error) =>
              of(
                AssistantActions.removeAssistantFailure({
                  error: error.message,
                })
              )
            )
          )
      )
    )
  );

  closeSaveAssistantModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistantModalActions.closeSaveAssistantModal),
      map(() => AssistantActions.unselectAssistant())
    )
  );
}
