import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  buffer,
  catchError,
  debounceTime,
  filter,
  finalize,
  forkJoin,
  map,
  mergeAll,
  of,
  switchMap,
  tap,
} from 'rxjs';
import * as fromGenerated from '../../../_generated';
import { FilesActions } from './files.actions';

@Injectable()
export class FilesEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly fileUploadService = inject(fromGenerated.FileUploadService);

  uploadFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilesActions.uploadFiles),
      buffer(
        this.actions$.pipe(ofType(FilesActions.uploadFiles), debounceTime(100))
      ),
      filter((actions) => actions.length > 0),
      tap(() =>
        this.store.dispatch(
          FilesActions.setIsFileLoading({ isFileLoading: true })
        )
      ),
      switchMap((actions) =>
        forkJoin(
          actions.map((action) =>
            this.fileUploadService
              .fileUploadControllerUploadFile(
                action.fileUploadTransactionId,
                action.file
              )
              .pipe(
                map((response) =>
                  FilesActions.uploadFilesSuccess({ file: response })
                ),
                catchError((error: Error) =>
                  of(
                    FilesActions.uploadFilesFailure({
                      error: `${action.file.name}: ${error.message}`,
                    })
                  )
                )
              )
          )
        ).pipe(
          mergeAll(),
          finalize(() =>
            this.store.dispatch(
              FilesActions.setIsFileLoading({ isFileLoading: false })
            )
          )
        )
      )
    )
  );
}
