import { HttpContext } from '@angular/common/http';
import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import * as fromGenerated from '../_generated';
import { IS_AUTH_INTERCEPTOR_ENABLED } from './auth.interceptor';

/**
 * This is a guard that checks if the user is authenticated
 * and make sure that the user is redirected to the chats page if so.
 */
export const loginGuard: CanActivateFn = () => {
  const authService = inject(fromGenerated.AuthService);

  return authService
    .authControllerRefreshTokens(undefined, undefined, {
      context: new HttpContext().set(IS_AUTH_INTERCEPTOR_ENABLED, false),
    })
    .pipe(
      map(() => true),
      catchError((error: Error) => {
        console.error(error);
        return of(true);
      })
    );
};
